<template>
   <div class="home text-center pt-4 pb-4">
    <div class="jumbotron pt-5 pb-5">      
      <h4 class="display-6"><a href="mailto:reach@brainbeam.tech">Email : reach@brainbeam.tech</a></h4>
      <h4 class="display-6"><a href="tel: +91 9986600371">Phone : +91 9632012833</a></h4>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>