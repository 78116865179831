<template>
  <footer
    class="page-footer font-small pt-4"
    style="background-color: #300000; color: #fdab14;"
  >
    <div class="container">
      <div class="row margin-30 hidden-xs hidden-sm">
        <div class="col-md-4">
          <p class="h4">Industries</p>
          <p>
            <a href="calc-section.php" class="disabled">Financial Services</a><br />
            <a href="clientportal.php" class="disabled">Healthcare</a><br />
            <a href="quick-send.php" class="disabled">Public Services</a><br />
            <a href="links.php" class="disabled">Consumer Services</a><br />
            <a href="newsletter.php" class="disabled">Manufacturing</a>
          </p>
        </div>
        <div class="col-md-4">
          <p class="h4">Services n Products</p>
          <p>
            <!-- <a href="taxrefunds.php">Manpower Consulting</a><br /> -->
            <router-link to="/manpower">Manpower Consulting</router-link><br />
             <router-link to="/techtraining">Technology Training</router-link><br />
            <router-link to="/cloudconsulting">Cloud Consulting</router-link><br />
            <router-link to="/appdevelopment">Application Development</router-link><br />
            <router-link to="/archconsulting">Architecture Consulting</router-link><br />
          </p>
        </div>
        <div class="col-md-4">
          <p class="h4">Contact</p>
          <p>
            <span style="color: white; font-weight: bold">BrainbeamSoft Technologies</span><br />
            6011, Sobha IRIS,<br />
            Outer Ring Road,<br />
            Devarabisanahalli,<br />
            Bangalore, India- 560103<br />
            <a href="tel: +91 9986600371">Tel: +91 9986600371 </a><br />
            <a href="mailto:reach@brainbeam.tech">Email: reach@brainbeam.tech</a>
          </p>
        </div>
      </div>
      <hr class="hidden-xs hidden-sm" />
      <div class="margin-30">
        <!-- Copyright -->
        <!-- <div class="footer-copyright text-center py-3"> -->
        <p class="margin-0 text-center pb-3">
          © 2021 Copyright:
          <a href="https://brainbeam.tech/" class="copy-right"> Brainbeam.Tech | </a>
          <!-- </div> -->
          <!-- Copyright -->

          <a href="sitemap.php" class="copy-right"> Site Map | </a>

          <a href="privacy.php" class="copy-right"> Privacy Policy | </a>

          <a href="disclaimer.php" class="copy-right"> Disclaimer | </a>

          <span class="hidden-sm hidden-xs"> </span>
          <span>
            <a
              class="fa fa-facebook copy-right"
              target="_blank"
              title="Facebook"
              href="https://www.facebook.com"
            ></a>
            <a
              class="fa fa-twitter copy-right"
              target="_blank"
              title="Twitter"
              href="https://www.twitter.com"
            ></a>
            <a
              class="fa fa-linkedin copy-right"
              target="_blank"
              title="LinkedIn"
              href="https://www.linkedin.com"
              primary
            ></a>
          </span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
ul li a {
  color: white;
}

a {
  color: white;
}

.copy-right {
  margin-right: 10px;
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  color: #fdab14;
}
a.disabled {
    pointer-events: none;
    color: #ccc;
}
</style>