<template>
  <div  @mouseleave="displaymenu" @mouseenter="hidemenu">
    <img src="../assets/images/arch-banner.png" class="img-fluid" alt="" />
  </div>
  <!-- <nav class="navbar sticky-top navbar-light"  v-if="showmenu">
    <div class="container-fluid">
      <router-link class="nav-item nav-item-color" to="/manpower">Manpower</router-link>
       <router-link class="nav-item nav-item-color" to="/techtraining">Tech Training</router-link>
        <router-link class="nav-item nav-item-color" to="/cloudconsulting">Cloud Consulting</router-link>
         <router-link class="nav-item nav-item-color" to="/appdevelopment">App Development</router-link>
          <router-link class="nav-item nav-item-color" to="/archconsulting">Arch Consulting</router-link>
           <router-link class="nav-item nav-item-color" to="/technologies">Technologies</router-link>
    </div>
  </nav> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="card border-0">
          <div
            class="card-header h2 fw-bold pt-3 text-secondary bg-white border-0"
          >
            Are you looking for a Architecture Consulting partner to perform any
            of the following services?
            <span class="service-text">You have reached the right team.</span>
          </div>
          <div class="card-body">
            <!-- <div class="row text-center">
              <div class="h2 fw-bold" style="color: #7b9cd0">
                Accelerate cloud migration using our Automation Platform
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                      <div class="card border-0 text-center">
                        <img src="../assets/images/archconsmainpic1.png" alt="" />
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                  <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Architecture Expert Team will help you defining
                      architecture addressing all NFR's with 360<sup>O</sup>
                      view.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2 pb-2">
            <div class="mt-1">
              <div class="mt-1 h3 fw-bold mb-3 col-md-6 mx-auto border border-2 rounded-pill p-2 text-white" style="background: #bd582c;">
                Our Architecture Consulting Portfolio
              </div>
              <div class="accordion" id="accordionExample">
                <div
                  class="accordion-item pb-2"
                  v-for="service in cloudServices"
                  :key="service.id"
                >
                  <h2 class="accordion-header" :id="'heading-' + service.id">
                    <button
                      class="accordion-button fw-bold text-white"
                      style="background: #da8a41"
                      :class="service.collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#' + service.id"
                      :aria-expanded="service.expanded"
                      :aria-controls="service.id"
                    >
                      {{ service.name }}
                    </button>
                  </h2>
                  <div
                    :id="service.id"
                    class="accordion-collapse collapse"
                    :class="service.show"
                    :aria-labelledby="'heading-' + service.id"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {{ service.body }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showmenu: false,
      cloudServices: [
        {
          name: "Enterprise Architecture",
          id: "collapse1",
          expanded: true,
          collapsed: "",
          show: "show",
          // body: `<strong>This is the first item's accordion body.</strong>
          //             It is shown by default, until the collapse plugin adds the
          //             appropriate classes that we use to style each element.
          //             These classes control the overall appearance, as well as
          //             the showing and hiding via CSS transitions. You can modify
          //             any of this with custom CSS or overriding our default
          //             variables. It's also worth noting that just about any HTML
          //             can go within the <code>.accordion-body</code>, though the
          //             transition does limit overflow.`,
        },
        {
          name: "Solution Architecture",
          id: "collapse2",
          expanded: false,
          show: "",
          collapsed: "collapsed",
          // body: `<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the 
          //           appropriate classes that we use to style each element. These classes control the overall appearance, as well as
          //             the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
          //             variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
          //             transition does limit overflow.`,
        },
        {
          name: "Integration Architecture",
          id: "collapse3",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application Architecture",
          id: "collapse4",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Data Architecture",
          id: "collapse5",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Micro Services Architecture",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "M365 Architecture",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
      ],
    };
  },
  methods: {
    displaymenu(){
      this.showmenu = true
    },
    hidemenu(){
      this.showmenu = false
    }
  }
};
</script>

<style scoped>
.service-text {
  color: #300000;
}
.nav-item-color{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover{
  color: #300000;
}
nav.sticky-top{
  padding-top: 105px; 
  background: #7b9cd0;
}
/* #DA8A41 */
.logo-brown {
  background: #da8a41;
  color: white;
}
</style>