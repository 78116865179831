
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      active: false,
    };
  },
  methods: {
    modalToggle() {
      // const body = document.querySelector("body");
      this.active = !this.active;
      // this.active
        // ? body.classList.add("modal-open")
        // : body.classList.remove("modal-open");
    },
  },
});
