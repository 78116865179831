<template>
  <div style="padding-top: 100px" class="pb-50">
    <div class="container-sm text-center zoom">
      <!-- <h1 class="display-4">Placeholder for Photo</h1>
      <p class="lead">
        This is a simple hero unit, a simple jumbotron-style component for
        calling extra attention to featured content or information.
      </p>
      <hr class="my-4" />
      <p>
        It uses utility classes for typography and spacing to space content out
        within the larger container.
      </p>

      <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a> -->
      <img
        src="../assets/images/ServicePortfolio.png"
        width="700"
        height="700"
        class="img-fluid"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.zoom {
  padding: 100px;
  transition: transform 0.2s; /* Animation */
  /* width: 200px;
  height: 200px; */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(
    1.25
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>