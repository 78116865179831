<template>
  <div class="container" style="padding-top: 125px">
    
      <button @click="handleClick">Test Me</button>
  </div>
</template>

<script>
export default {
  methods:{
    handleClick() {
      console.log('Click handled')
    }
  }
};
</script>

<style>
</style>

