import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Portfolio from '@/views/Portfolio.vue'
import NotFound from '@/views/NotFound.vue'
import ContactModal from '@/components/layout/ContactModal.vue'
import Technologies from '@/views/Technologies.vue'
import Manpower from '@/views/Manpower.vue'
import ArchConsulting from '@/views/ArchConsulting.vue'
import AppDevelopment from '@/views/AppDevelopment.vue'
import TechTraining from '@/views/TechTraining.vue'
import CloudConsulting from '@/views/CloudConsulting.vue'
import Contact from '@/views/Contact.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect : '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/contactmodal',
    name: 'ContactModal',
    component: ContactModal
    
  }, 
  {
    path: '/technologies',
    name: 'Technologies',
    component: Technologies
    
  }, 
  {
    path: '/manpower',
    name: 'Manpower',
    component: Manpower
    
  }, 
  {
    path: '/cloudconsulting',
    name: 'CloudConsulting',
    component: CloudConsulting
    
  }, 
  {  
  path: '/techtraining',
  name: 'TechTraining',
  component: TechTraining
  
}, 
  {
    path: '/appdevelopment',
    name: 'AppDevelopment',
    component: AppDevelopment
    
  }, 
  {
    path: '/archconsulting',
    name: 'ArchConsulting',
    component: ArchConsulting
    
  }, 
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
    
  }, 
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
