<template>
  <div @mouseleave="displaymenu" @mouseenter="hidemenu">
    <img src="../assets/images/cloud-banner.png" class="img-fluid" alt="" />
  </div>
  <!-- <nav class="navbar sticky-top navbar-light"  v-if="showmenu">
    <div class="container-fluid">
      <router-link class="nav-item nav-item-color" to="/manpower">Manpower</router-link>
       <router-link class="nav-item nav-item-color" to="/techtraining">Tech Training</router-link>
        <router-link class="nav-item nav-item-color" to="/cloudconsulting">Cloud Consulting</router-link>
         <router-link class="nav-item nav-item-color" to="/appdevelopment">App Development</router-link>
          <router-link class="nav-item nav-item-color" to="/archconsulting">Arch Consulting</router-link>
           <router-link class="nav-item nav-item-color" to="/technologies">Technologies</router-link>
    </div>
  </nav> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="card border-0">
          <div
            class="card-header h2 fw-bold pt-3 text-secondary bg-white border-0"
          >
            Are you looking for a cloud consulting partner to perform any of the
            following services?
            <span class="service-text">You have reached the right team.</span>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="h2 fw-bold" style="color: #7b9cd0 ">
                Accelerate cloud migration using our Automation Platform
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0 ">
                 <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                      <div class="card border-0">
                        <img
                          src="../assets/images/ccmainpic1.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                  <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Cloud Expert Team has a comprehensive capabilities to
                      help your cloud migration journey end-to-end.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            
            <div class="mt-3">
              <div class="mt-1 h3 fw-bold mb-3 col-md-6 mx-auto border border-2 rounded-pill p-2 text-white" style="background: #bd582c;">Our Cloud Consulting Portfolio</div>
              <div class="container-fluid">
              <div class="row mt-2 mb-2">
                <div class="col-md-4 d-flex justify-content-center">
                    <img src="../assets/images/gcplogo.png" class="img-fluid" alt="">
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                    <img src="../assets/images/azurelogo.png" class="img-fluid"  alt="">
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                    <img src="../assets/images/awslogo.png" class="img-fluid"  alt="">
                </div>              
              </div>
            </div>
              <div class="accordion" id="accordionExample">
                <div
                  class="accordion-item pb-2"
                  v-for="service in cloudServices"
                  :key="service.id"
                >
                  <h2 class="accordion-header" :id="'heading-' + service.id">
                    <button
                      class="accordion-button fw-bold text-white"
                      style="background: #da8a41"
                      :class="service.collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#' + service.id"
                      :aria-expanded="service.expanded"
                      :aria-controls="service.id"
                    >
                      {{ service.name }}
                    </button>
                  </h2>
                  <div
                    :id="service.id"
                    class="accordion-collapse collapse"
                    :class="service.show"
                    :aria-labelledby="'heading-' + service.id"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {{ service.body }}
                    </div>
                  </div>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showmenu: false,
      cloudServices: [
        {
          name: "Cloud Adoption Strategy",
          id: "collapse1",
          expanded: true,
          collapsed: "",
          show: "show",
          // body: `Cloud Adoption strategy is an excercise done by an enterprise to define and explore
          // methods to adopt cloud services to reduce CAPEX and OPEX cost, there by improving
          // business agility. To achieve this, corporates engage with cloud service providers and 
          // cloud solution consulting companies to study their Infrastructure Landscape, Technology Landscape,
          // Application Landscape, Data Landscape, Integration Landscape to execute a detailed 
          // analysis and define cloud adoption strategy.`,
        },
        {
          name: "Enterprise Maturity Assessment",
          id: "collapse2",
          expanded: false,
          show: "",
          collapsed: "collapsed",
          body: '',
        },
        {
          name: "Enterprise Cloud Readiness Assessement",
          id: "collapse3",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Migration Strategy",
          id: "collapse4",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Public Cloud Services",
          id: "collapse5",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Private Cloud Services",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Hybrid Cloud Services",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application Migration",
          id: "collapse8",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application Modernization",
          id: "collapse9",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Native Application Design and Development",
          id: "collapse10",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Data Migration",
          id: "collapse11",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application and Data Integration",
          id: "collapse12",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Data Analytics",
          id: "collapse13",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Agnostic Application Modernization",
          id: "collapse14",
          expanded: false,
          collapsed: "collapsed",
        },
      ],
    };
  },
  methods: {
    displaymenu(){
      this.showmenu = true
    },
    hidemenu(){
      this.showmenu = false
    }
  }
};
</script>

<style scoped>
.service-text {
  color: #300000;
}
/* #DA8A41 */
.logo-brown {
  background: #da8a41;
  color: white;
}
.nav-item-color{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover{
  color: #300000;
}
nav.sticky-top{
  background: #7b9cd0;
}
</style>