<template>
  <div class="home text-center" style="padding-top: 100px">
    <div class="jumbotron">
      <h4 class="display-4">404 - Sorry! Page Not Found</h4>
      <p class="lead">
        Please try after some time or contact the support team at reach@brainbeam.tech
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: var(--bs-light);
  border-radius: 0.3rem;
}

</style>