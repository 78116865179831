<template>
  <div @mouseleave="displaymenu" @mouseenter="hidemenu">
      <img src="../assets/images/devlanding.png" class="img-fluid" alt="">
  </div>
  <!-- <nav class="navbar sticky-top navbar-light"  v-if="showmenu">
    <div class="container-fluid">
      <router-link class="nav-item nav-item-color" to="/manpower">Manpower</router-link>
       <router-link class="nav-item nav-item-color" to="/techtraining">Tech Training</router-link>
        <router-link class="nav-item nav-item-color" to="/cloudconsulting">Cloud Consulting</router-link>
         <router-link class="nav-item nav-item-color" to="/appdevelopment">App Development</router-link>
          <router-link class="nav-item nav-item-color" to="/archconsulting">Arch Consulting</router-link>
           <router-link class="nav-item nav-item-color" to="/technologies">Technologies</router-link>
    </div>
  </nav> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="card border-0">
          <div
            class="card-header h2 fw-bold pt-3 text-secondary bg-white border-0"
          >
            Are you looking for a cloud consulting partner to perform any of the
            following services?
            <span class="service-text">You have reached the right team.</span>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="h2 fw-bold" style="color: #7b9cd0 ">
                Accelerate cloud migration using our Automation Platform
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0 ">
                 <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                      <div class="card border-0">
                        <img
                          src="@/assets/images/sdmmainpic.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                  <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Cloud Expert Team has a comprehensive capabilities to
                      help your cloud migration journey end-to-end.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2 pb-2">
            <div class="mt-1">
              <div class="mt-1 h3 fw-bold mb-3 col-md-6 mx-auto border border-2 rounded-pill p-2 text-white" style="background: #bd582c;">Our App Development Portfolio</div>
                            
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Web Technologies</h2>
                 <img
                  src="../assets/images/webtech.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Database Technologies</h2>
                 <img
                  src="../assets/images/dbms.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">M365 Technologies</h2>
                 <img
                  src="../assets/images/M365.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Devops Technologies</h2>
                 <img
                  src="../assets/images/devops.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3 mb-3">
                <h2 class="mt-5 mb-5">Mobile Technologies</h2>
                 <img
                  src="../assets/images/mobiletech.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {

    return {
      showmenu: false,
      cloudServices: [
        {
          name: "Cloud Adoption Strategy",
          id: "collapse1",
          expanded: true,
          collapsed: "",
          show: "show",
          body: `<strong>This is the first item's accordion body.</strong>
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.`,
        },
        {
          name: "Enterprise Maturity Assessement",
          id: "collapse2",
          expanded: false,
          show: "",
          collapsed: "collapsed",
          body: `<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the 
                    appropriate classes that we use to style each element. These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.`,
        },
        {
          name: "Enterprise Cloud Readiness Assessement",
          id: "collapse3",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Migration Strategy",
          id: "collapse4",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Public Cloud Service'",
          id: "collapse5",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Private Cloud Services",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Hybrid Cloud Services",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application Migration",
          id: "collapse8",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application Modernization",
          id: "collapse9",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Native Application Design and Development",
          id: "collapse10",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Data Migration",
          id: "collapse11",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Application and Data Integration",
          id: "collapse12",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Data Analytics",
          id: "collapse13",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Agnostic Application Modernization",
          id: "collapse14",
          expanded: false,
          collapsed: "collapsed",
        },
      ],
    };
  },
  methods: {
    displaymenu(){
      this.showmenu = true
    },
    hidemenu(){
      this.showmenu = false
    }
  }
};
</script>

<style scoped>
.service-text {
  color: #300000;
}
/* #DA8A41 */
.logo-brown {
  background: #da8a41;
  color: white;
}
.nav-item-color{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover{
  color: #300000;
}
nav.sticky-top{
  padding-top: 105px; 
  background: #7b9cd0;
}
</style>