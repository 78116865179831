
import Navbar from '@/components/layout/Navbar.vue'
import Footer from '@/components/layout/Footer.vue'
// import ContactModal from '@/components/layout/ContactModal.vue'
export default ({
   data() {
    return {
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "in", language: "ta", title: "தமிழ்" },
      ],
    };
  },
  methods: {
    changeLocale() {
      // i18n.global.locale = lang
    },
  },
    components: {
      Navbar,
      Footer,
      // ContactModal
    }
})
