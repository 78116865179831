<template>
<div class="border-0" @mouseleave="displaymenu" @mouseenter="hidemenu">
    <img src="../assets/images/tech-banner.png" class="img-fluid" alt="" />
  </div>
  <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                      <div class="card border-0 text-center">
                        <img src="../assets/images/techmainpic1.png" alt="" />
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                  <!-- <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Architecture Expert Team will help you defining
                      architecture addressing all NFR's with 360<sup>O</sup>
                      view.
                    </h5>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
  <div style="padding: 20px 30px 30px 30px">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active text-secondary"
          id="webtech-tab"
          data-bs-toggle="tab"
          data-bs-target="#webtech"
          type="button"
          role="tab"
          aria-controls="webtech"
          aria-selected="true"
        >
          Web Tech
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="database-tab"
          data-bs-toggle="tab"
          data-bs-target="#database"
          type="button"
          role="tab"
          aria-controls="database"
          aria-selected="false"
        >
          Database
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="m365-tab"
          data-bs-toggle="tab"
          data-bs-target="#m365"
          type="button"
          role="tab"
          aria-controls="m365"
          aria-selected="false"
        >
          M365
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="devops-tab"
          data-bs-toggle="tab"
          data-bs-target="#devops"
          type="button"
          role="tab"
          aria-controls="devops"
          aria-selected="false"
        >
          DevOps
        </button>
      </li>
       <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="mobiletech-tab"
          data-bs-toggle="tab"
          data-bs-target="#mobiletech"
          type="button"
          role="tab"
          aria-controls="mobiletech"
          aria-selected="false"
        >
          Mobile Tech
        </button>
      </li>
    </ul>
    <!-- <div class="container" style="padding-top: 20px"> -->
    <div class="tab-content" style="padding-top: 20px" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="webtech"
        role="tabpanel"
        aria-labelledby="webtech-tab"
      >
        <div class="conainer-sm text-center">
          <img
            src="../assets/images/webtech.png"
            class="img-fluid"
            width="600"
            height="600"
            alt=""
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="database"
        role="tabpanel"
        aria-labelledby="database-tab"
      >
        <div class="conainer-sm text-center">
          <img
            src="../assets/images/dbms.png"
            class="img-fluid"
            width="600"
            height="600"
            alt=""
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="m365"
        role="tabpanel"
        aria-labelledby="m365-tab"
      >
        <div class="conainer-sm text-center">
          <img
            src="../assets/images/M365.png"
            class="img-fluid"
            width="600"
            height="600"
            alt=""
          />
        </div>
      </div>
      
      <div
        class="tab-pane fade"
        id="devops"
        role="tabpanel"
        aria-labelledby="devops-tab"
      >
        <div class="conainer-sm text-center">
         <div class="row">
           <div class="col-md-12">
              <img
            src="../assets/images/devops.png"
            class="img-fluid"
            width="600"
            height="600"
            alt=""
          />
           </div>
         </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="mobiletech"
        role="tabpanel"
        aria-labelledby="mobiletech-tab"
      >
        <div class="conainer-sm text-center">
         <div class="row">
           <div class="col-md-12">
              <img
            src="../assets/images/mobiletech.png"
            class="img-fluid"
            width="600"
            height="600"
            alt=""
          />
           </div>
         </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      showmenu: false,
    }
  },
  methods: {
    displaymenu(){
      this.showmenu = true
    },
    hidemenu(){
      this.showmenu = false
    }
  }
};
</script>

<style scoped>
.zoom {
  padding: 60px 100px 100px 100px;
  transition: transform 0.2s; /* Animation */
  /* width: 200px;
  height: 200px; */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(
    1.25
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.nav-item-color{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover{
  color: #300000;
}
nav.sticky-top{
  padding-top: 105px; 
  background: #7b9cd0;
}
</style>