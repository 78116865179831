
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    showmodal() {
      console.log("You are in Manpower Consulting");
      this.$router.push("/contactmodal");
    },
    exploreTech() {
      this.$router.push("/technologies");
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    manpower() {
      this.$router.push("/manpower");
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    cloudconsulting() {
      this.$router.push("/cloudconsulting");
      window.scrollTo(0, 0);
    },
    appdevelopment() {
      this.$router.push("/appdevelopment");
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    techtraining() {
      this.$router.push("/techtraining");
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    archconsulting() {
      this.$router.push("/archconsulting");
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    modalToggle() {
      const body = document.querySelector("body");
      this.active = !this.active;
      // ? body.classList.add("modal-open")
      // : body.classList.remove("modal-open");
    },
  },
});
