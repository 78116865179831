<template>
  <div  @mouseleave="displaymenu" @mouseenter="hidemenu">
    <img
      src="../assets/images/training-banner.png" class="img-fluid"
      alt=""
    />
  </div>
   <div class="container-fluid" >
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="card border-0">
          <div
            class="card-header h2 fw-bold pt-3 text-secondary bg-white border-0"
          >
            Are you looking for a Technology Training Partner to mentor your development team on latest modern technologies?
            <span class="service-text">You have reached the right team.</span>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="h2 fw-bold" style="color: #7b9cd0 ">
                Accelerate mentoring your development team to meet your project needs
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card border-0 ">
                  
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                      <div class="card border-0">
                    <img
                    src="../assets/images/traningmainpic.png"

                    alt=""
                    class="card-img-top img-responsive"
                  />
                    </div>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                  <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Training Experts will train the technology using different realtime use cases.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2 pb-2">
            <div class="mt-1">
              <div class="mt-1 h3 fw-bold mb-3 col-md-6 mx-auto border border-2 rounded-pill p-2 text-white" style="background: #bd582c;">Our Technology Training Portfolio</div>
              <!-- <div class="accordion" id="accordionExample">
                <div
                  class="accordion-item pb-2"
                  v-for="service in cloudServices"
                  :key="service.id"
                >
                  <h2 class="accordion-header" :id="'heading-' + service.id">
                    <button
                      class="accordion-button fw-bold text-white"
                      style="background: #da8a41"
                      :class="service.collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#' + service.id"
                      :aria-expanded="service.expanded"
                      :aria-controls="service.id"
                    >
                      {{ service.name }}
                    </button>
                  </h2>
                  <div
                    :id="service.id"
                    class="accordion-collapse collapse"
                    :class="service.show"
                    :aria-labelledby="'heading-' + service.id"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {{ service.body }}
                    </div>
                  </div>
                </div>               
              </div> -->
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Web Technologies</h2>
                 <img
                  src="../assets/images/webtech.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Database Technologies</h2>
                 <img
                  src="../assets/images/dbms.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">M365 Technologies</h2>
                 <img
                  src="../assets/images/M365.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3">
                <h2 class="mt-5 mb-5">Devops Technologies</h2>
                 <img
                  src="../assets/images/devops.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
              <div class="mt-3 mb-3">
                <h2 class="mt-5 mb-5">Mobile Technologies</h2>
                 <img
                  src="../assets/images/mobiletech.png"
                  class="img-fluid"
                  width="600"
                  height="600"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showmenu: false,
      cloudServices: [
        {
          name: "Cloud Technologies",
          id: "collapse1",
          expanded: true,
          collapsed: "",
          show: "show",
          body: `<strong>This is the first item's accordion body.</strong>
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.`,
        },
        {
          name: "Front End Technologies",
          id: "collapse2",
          expanded: false,
          show: "",
          collapsed: "collapsed",
          body: `<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the 
                    appropriate classes that we use to style each element. These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.`,
        },
        {
          name: "Back End Technologies",
          id: "collapse3",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "DevSecOps Automation Technologies",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Database Technologies",
          id: "collapse5",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Integration Technologies",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Collaboration Technologies",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Containerization Technologies",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        }
      ],
    };
  },
  methods: {
    displaymenu(){
      this.showmenu = true
    },
    hidemenu(){
      this.showmenu = false
    }
  }
};
</script>

<style scoped>
.service-text {
  color: #300000;
}
/* #DA8A41 */
.logo-brown {
  background: #da8a41;
  color: white;
}
.nav-item-color{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover{
  color: #300000;
}
nav.sticky-top{
  /* padding-top: 105px;  */
  background: #300000;
}
</style>