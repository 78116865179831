<template>
  <div @mouseleave="displaymenu" @mouseenter="hidemenu">
    <img src="../assets/images/manpower-banner.png" class="img-fluid" alt="" />
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="card border-0">
          <div
            class="card-header h2 fw-bold pt-3 text-secondary bg-white border-0"
          >
            Are you looking for a manpower consulting partner to help you
            identify and place rightly skilled resources?
            <span class="service-text">You have reached the right team.</span>
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="h2 fw-bold" style="color: #7b9cd0">
                Accelerate your talent placement using our Automation Platform
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                      <div class="card border-0">
                        <img
                          src="../assets/images/manpowermainpic.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                  </div>

                  <div class="card-body" style="background: #7b9cd0">
                    <h5 class="card-text text-white">
                      Our Manpower Consulting Team has a comprehensive
                      capabilities to help you placing right skilled resources.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-1 h3 fw-bold mb-3 col-md-6 mx-auto border border-2 rounded-pill p-2 text-white" style="background: #bd582c;">
          Our Manpower Consulting Model
        </div>
      </div>

        <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-md-4 text-center my-auto">
              <img
                src="@/assets/images/pwd.png"
                alt=""
                width="400"
                height="400"
                class="img-fluid"
              />
            </div>
            <div class="col-md-8">
              <div class="card border-0">
                <div class="card-body my-auto text-left ">
                  <div class="card-title">
                    <h3 class="fw-bold">Professional workforce deployment</h3>
                  </div>
                  <p>BrainbeamSoft team is well-versed in professional manpower
                  placement through comprehensive workforce management. Our
                  drive is to assist our Customers and candidates conquer the
                  ever-changing work ecosphere of the digital age.</p>                  
                  <ul class="list-group">
                    <li class="list-group-item border-0">
                      <i class="fa-solid fa-circle-check"></i>
                      <span>  Talented Pool of professionals with high technical
                      knowledge</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> Meticulous Search and Assessment process in terms of
                      selecting the right candidates</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> 25 years of Mentoring people for roles such as Architects,
                      Technical Leads, Business Analysts, Developers and Testing
                      professionals</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                       <span>
                      Integrated Talent Development (ITD) - Comprehensive Talent
                      Development include the development on both technical and
                      behavioral skills with diverse set of people.
                       </span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                       <span>
                      Focus on Digital Age Technologies relevant to the current
                      and futuristic technology and business marketplace.
                       </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <div class="card border-0">
                <div class="card-body">
                  <div class="card-title">
                    <h3 class="fw-bold"> Consulting and Delivery Partnership</h3>
                  </div>
                  In BrainbeamSoft, we not
                  only provide the talented professionals, but also partner with
                  the Organization to provide the appropriate guidance in all
                  the delivery aspects of the projects our team is undertaking
                  using our Consulting and Delivery Partnership principles.
                </div>
                <ul class="list-group">
                    <li class="list-group-item border-0">
                      <i class="fa-solid fa-circle-check"></i>
                      <span> Shared responsibilities: Distinct definition of roles and responsibilities among team members to establish focused efforts.
</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> Shared Ownership:  Execution of the projects with the clear accountability for the shared work
Flexible Engagement Model: Support personalized engagement models to support effective execution based on multiple delivery options
</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> Long term commitment: Our commitment starts from initial phase to go-live phase for the projects and beyond that in terms of support, maintenance and future enhancements.</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                       <span>
                      Remote/Onsite working model: Transparent work allocations and tracking to aid project execution from remote locations.

                       </span>
                    </li>
                  </ul>
              </div>
            </div>
            <div class="col-md-4">
              <img src="@/assets/images/cdp.png" alt="" width="400" height="400" class="img-fluid" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4 text-center my-auto">
              <img
                src="@/assets/images/pwd.png"
                alt=""
                width="400"
                height="400"
                class="img-fluid"
              />
            </div>
            <div class="col-md-8">
              <div class="card border-0">
                <div class="card-body my-auto text-left ">
                  <div class="card-title">
                    <h3 class="fw-bold">Right Fit Candidate Positioning</h3>
                  </div>
                  <p>Professional team available for various roles</p>                  
                  <ul class="list-group">
                    <li class="list-group-item border-0">
                      <i class="fa-solid fa-circle-check"></i>
                      <span> Cloud Architect, Cloud Developers</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> Full stack Application Developers</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                      <span> Highly skilled Project Managers</span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                       <span>
                      Manual and Automation Testers
                       </span>
                    </li>
                    <li class="list-group-item border-0">
                       <i class="fa-solid fa-circle-check"></i>
                       <span>
                        DevSecOps Personnel
                       </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showmenu: false,
      cloudServices: [
        {
          name: "Front-End Developers",
          id: "collapse1",
          expanded: true,
          collapsed: "",
          show: "show",
          // body: `<strong>This is the first item's accordion body.</strong>
          //             It is shown by default, until the collapse plugin adds the
          //             appropriate classes that we use to style each element.
          //             These classes control the overall appearance, as well as
          //             the showing and hiding via CSS transitions. You can modify
          //             any of this with custom CSS or overriding our default
          //             variables. It's also worth noting that just about any HTML
          //             can go within the <code>.accordion-body</code>, though the
          //             transition does limit overflow.`,
        },
        {
          name: "Back-End Developers",
          id: "collapse2",
          expanded: false,
          show: "",
          collapsed: "collapsed",
          // body: `<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the
          //           appropriate classes that we use to style each element. These classes control the overall appearance, as well as
          //             the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
          //             variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
          //             transition does limit overflow.`,
        },
        {
          name: "Full Stack Developers",
          id: "collapse3",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Devops Engineers",
          id: "collapse4",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Database Developers",
          id: "collapse5",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Cloud Solution Architects",
          id: "collapse6",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Technology Solution Architects",
          id: "collapse7",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Database Architects",
          id: "collapse8",
          expanded: false,
          collapsed: "collapsed",
        },
        {
          name: "Microservices Architects",
          id: "collapse9",
          expanded: false,
          collapsed: "collapsed",
        },
      ],
    };
  },
  methods: {
    displaymenu() {
      this.showmenu = true;
    },
    hidemenu() {
      this.showmenu = false;
    },
    gotocloudconsulting() {
      this.$router("/cloudconsulting");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.service-text {
  color: #300000;
}
/* #DA8A41 */
.logo-brown {
  background: #da8a41;
  color: white;
}
.nav-item-color {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.nav-item-color:hover {
  color: #300000;
}
nav.sticky-top {
  /* padding-top: 105px;  */
  background: #7b9cd0;
}

ul li i{
  color: #bd582c;
}
</style>


